import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { refreshSessionId } from '../utils/session_id';
import { rhythm } from '../utils/typography';
import Layout from '../components/layout';
import { colors } from '../components/colors';
import * as cssStyles from './contact.module.css';
import { hr } from '../components/brief/lines';
import * as linkCssStyles from '../components/link.module.css';

import { RenderHead } from "../components/head";
export const Head = () => RenderHead({ title: "Contact Us" });



// width: 100% is required for IE to honor the .container bounds

const styles = {
    
    heading: {
        width: '100%',
        margin: `${rhythm(4.5)} 0 0`,
        fontFamily: '"Overpass", sans-serif',
        fontSize: '30px',
        fontWeight: 'bold',
        lineHeight: '1.4',
        color: colors.green,
        textAlign: 'center'
    },

    subHeading: {
        width: '100%',
        fontFamily: '"Overpass", sans-serif',
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '1.25',
        color: colors.black,
        textAlign: 'center'
    },

    body: {
        width: '100%',
        margin: '5px 0 0',
        fontSize: '15px',
        lineHeight: '1.87',
        letterSpacing: '0.15px',
        color: colors.black,
        textAlign: 'center'
    },

    link: {
        margin: '0 3px',
    }
};


const merge = (a, b) => {
    var n = Object.assign({}, a);
    return Object.assign(n, b);
};




const Contact = ({ location }) => {

    useEffect(() => { refreshSessionId(); }, []);
    
    return (
        <Layout
          location={ location } >                    

          <div
            style={{
                width: `100%`,
                overflow: `hidden`,
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `flex-start`,
                alignItems: `center`
            }} >

            <div
              className={ cssStyles.container }
              style={{
                  display: `flex`,
                  flexDirection: `column`,
                  justifyContent: `flex-start`,
                  alignItems: `center`
              }} >

              <div
                style={
                    merge(styles.heading,
                          {
                              margin: `47px 0 0`,
                              textAlign: `center`
                          })
                } >
                Contact Us
              </div>

              <div
                style={
                    merge(styles.subHeading,
                          { margin: `45px 0 0` })
                } >
                Feedback
              </div>

              <div
                style={ styles.body } >
                We’d love to hear your feedback on any detail, large or small. Share it with us at
                <OutboundLink
                  style={ styles.link }
                  className={ linkCssStyles.link }
                  href='mailto:hello@6pages.com' >
                  hello@6pages.com
                </OutboundLink>
                .
              </div>

              <div
                style={
                    merge(styles.subHeading,
                          { margin: `53px 0 0` })
                } >
                Tips
              </div>

              <div
                style={ styles.body } >
                Have a topic you’d like to see us cover? Send us a note at 
                <OutboundLink
                  style={ styles.link }
                  className={ linkCssStyles.link }
                  href='mailto:tips@6pages.com'>
                  tips@6pages.com
                </OutboundLink>
                .
              </div>

              <div
                style={
                    merge(styles.subHeading,
                          { margin: `53px 0 0` })
                } >
                Support
              </div>

              <div
                style={ styles.body } >
                <p
                  style={{ margin: 0 }} >
                  For support or technical questions, email us at
                  <OutboundLink
                    style={ styles.link }
                    className={ linkCssStyles.link }
                    href='mailto:help@6pages.com'>
                    help@6pages.com
                  </OutboundLink>
                  .
                </p>
                <p
                  style={{ margin: 0 }} >
                  A team member will respond within 24 hours!
                </p>
                
                
              </div>

              <div
                style={
                    merge(styles.subHeading,
                          { margin: `53px 0 0` })
                } >
                Corporate
              </div>

                <div style={ styles.body } >
                    
                    We offer a range of
                    
                    <Link
                        style={ styles.link }
                        className={ linkCssStyles.link }
                        to='/enterprise' >
                        enterprise packages and services
                    </Link>

                    . To hear about them, please email us at
                    
                    <OutboundLink
                        style={ styles.link }
                        className={ linkCssStyles.link }
                        href='mailto:business@6pages.com'>
                        business@6pages.com
                    </OutboundLink>
                    
                    and we can set up a quick call to chat.
                    
                </div>


              <div
                className={ cssStyles.hr }
                style={{ margin: `125px 0 0` }} >
                { hr() }
              </div>

              <div
                style={
                    merge(styles.subHeading,
                          { margin: `125px 0 0` })
                } >
                Follow us on social
              </div>

              <div
                style={ styles.body } >
                  We share alerts when our latest briefs are released, as well as news about 6Pages.
              </div>

              <div
                style={{
                    margin: `28px 0 0`,
                    display: `flex`,
                    flexDirection: `row`,
                    justifyContent: `flex-start`,
                    alignItems: `center`
                }} >
                <OutboundLink
                  style={{
                      boxShadow: `none`,
                      textDecoration: `none`
                  }}
                  href='https://twitter.com/6PagesInc'
                  target='_blank'
                  rel='noopener noreferrer' >
                  <i
                    className={ 'fab fa-lg fa-twitter' }
                    style={{ color: colors.black }} ></i>
                </OutboundLink>
                <OutboundLink
                  style={{
                      margin: `0 0 0 50px`,
                      boxShadow: `none`,
                      textDecoration: `none`
                  }}
                  href='https://www.linkedin.com/company/6pagesinc'
                  target='_blank'
                  rel='noopener noreferrer' >
                  <i
                    className={ 'fab fa-lg fa-linkedin-in' }
                    style={{ color: colors.black }} ></i>
                </OutboundLink>
              </div>
              

              <div
                style={{
                    width: `1px`,
                    height: `105px`,
                    background: `transparent`
                }} />

            </div>
            
          </div>
          
        </Layout>
    );
};

export default Contact;
